import { useEffect, useState, useCallback } from "react";

type ScrollPosition = {
  x: number;
  y: number;
};

type UseScrollPositionReturn = [ScrollPosition, (options: ScrollToOptions) => void];

const useScrollPosition = (): UseScrollPositionReturn => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  const scrollTo = useCallback((options: ScrollToOptions) => {
    window.scrollTo(options);
  }, []);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition({ x: window.scrollX, y: window.scrollY });
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return [scrollPosition, scrollTo];
};

export default useScrollPosition;

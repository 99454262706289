import type { ReactElement } from 'react';
import LogoSVG from '../../../public/images/2arrows-logo.svg';
import ScrollSVG from '../../../public/images/footer-button.svg';
import { appRoutes } from 'constants/appRoutes';
import Link from 'next/link';
import useScrollPosition from 'hooks/useScroll';

const Address = () => (
  <div className={styles.address}>
    <p>38 York Street<br />Sydney, NSW, 2000</p>
  </div>
);

const Services = () => (
  <div className="ml-24 pl-24">
    <h3 className={styles.services}>Services</h3>
    <ul role="list" className={styles.servicesList}>
      {[
        { title: 'Leadership', link: '#' },
        { title: 'Design', link: '#' },
        { title: 'Engineering', link: '#' },
        { title: 'Product', link: '#' },
        { title: 'Marketing', link: '#' },
      ].map(({ title, link }) => (
        <li key={title}>
          <a href={link} className={styles.link}>{title}</a>
        </li>
      ))}
    </ul>
  </div>
);

const Company = () => (
  <div className="mt-4 md:mt-0">
    <h3 className={styles.services}>Company</h3>
    <ul role="list" className={styles.list}>
      {[
        { title: 'About us', link: '#about' },
        { title: 'FAQs', link: '#' },
        { title: 'Contact', link: appRoutes.HELP_CENTRE },
      ].map(({ title, link }) => (
        <li key={title}>
          <a href={link} className={styles.link}>{title}</a>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = (): ReactElement => {
  const [, scrollTo] = useScrollPosition();

  const scrollToTop = () => {
    scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-stone-900" aria-labelledby="footer-heading">
      <div className={styles.container}>
        <div className={styles.features}>
          <div className="space-y-4">
            <div className={styles.logo}>
              <Link href={appRoutes.HOME}>
                <LogoSVG className="text-white" />
              </Link>
              <Address />
            </div>
          </div>
          <div className={styles.contents}>
            <Services />
            <div className={styles.company}>
              <Company />
              <div className={styles.scroll}>
                <button onClick={scrollToTop}>
                  <ScrollSVG />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <p className={styles.copyright}>
            Copyright &copy; 2023 2Arrows | All rights Reserved |
            <a href={appRoutes.TERMS_OF_SERVICE} className="underline hover:text-gray-300">Terms and Conditions</a> |
            <a href={appRoutes.PRIVACY_POLICY} className="underline hover:text-gray-300">Privacy Policy</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  address: "text-white font-helvetica text-right font-normal text-xl ml-8",
  services: "font-helvetica text-sm font-bold leading-6 text-white",
  link: "text-lg font-normal leading-6 text-white",
  list: "mt-4 text-white text-lg font-light space-y-4",
  servicesList: "mt-4 text-white text-lg font-light space-y-4",
  container: "mx-auto max-w-7xl px-6 pb-4 pt-8 sm:pt-12 lg:px-8 lg:pt-16",
  features: "xl:grid xl:grid-cols-3 xl:gap-8",
  logo: "flex justify-between items-center",
  contents: "mt-8 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0",
  company: "md:grid font-helvetica md:grid-cols-2 md:gap-8 md:pl-24",
  scroll: "mt-4 md:mt-0 flex justify-end items-start",
  bottomContainer: "mt-8 pt-4 sm:mt-10 lg:mt-12",
  copyright: "text-sm font-sans leading-5 text-white"
};

export default Footer;

import Head from 'next/head';
import React from 'react';
import Header from 'modules/Header';
import Footer from 'modules/Footer';
import NoSSRWrapper from 'components/hocs/NoSSRWrapper';
import { BackgroundColor } from 'utils/ColorUtils';
import { useHasSSREnabled } from 'hooks/useHasSSREnabled';

type Props = {
  children: React.ReactNode;
  backgroundColor?:  BackgroundColor;
  isHeaderHidden?: boolean;
  isHeaderTransparent?: boolean;
  isFooterHidden?: boolean;
  isFooterTransparent?: boolean;
}

const Default = ({
  children,
  backgroundColor = BackgroundColor.White,
  isHeaderHidden = false,
  isHeaderTransparent = false,
  isFooterHidden = false,
  isFooterTransparent = false
}: Props): JSX.Element => {

  return (
    <div className={"flex flex-col min-h-screen justify-between " + backgroundColor.valueOf()}>
      { !isHeaderHidden && 
        <NoSSRWrapper>
          <Header isTransparent={isHeaderTransparent} />
        </NoSSRWrapper>
      }

      <Head>
        <title>&#8970;2&#8969; Arrows</title>
        <meta name="description" content="2 Arrows" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>{children}</main>

      { !isFooterHidden &&
        <Footer />
      }
    </div>
  );
}

const LayoutWrapper = ({
  page,
  backgroundColor,
  isHeaderHidden,
  isHeaderTransparent,
  isFooterHidden,
  isFooterTransparent
}: {
  page: JSX.Element,
  backgroundColor: BackgroundColor,
  isHeaderHidden: boolean,
  isHeaderTransparent: boolean,
  isFooterHidden: boolean,
  isFooterTransparent: boolean
}): JSX.Element => {
  const ssrEnabled = useHasSSREnabled();

  return ssrEnabled ? (
    <Default
      backgroundColor={backgroundColor}
      isHeaderHidden={isHeaderHidden}
      isHeaderTransparent={isHeaderTransparent}
      isFooterHidden={isFooterHidden}
      isFooterTransparent={isFooterTransparent}>
      {page}
    </Default>
  ) : (
    <NoSSRWrapper>
      <Default
        backgroundColor={backgroundColor}
        isHeaderHidden={isHeaderHidden}
        isHeaderTransparent={isHeaderTransparent}
        isFooterHidden={isFooterHidden}
        isFooterTransparent={isFooterTransparent}>
        {page}
      </Default>
    </NoSSRWrapper>
  );
};

export const getLayout = (
  page: JSX.Element,
  backgroundColor: BackgroundColor,
  isHeaderHidden: boolean,
  isHeaderTransparent: boolean,
  isFooterHidden: boolean,
  isFooterTransparent: boolean
): JSX.Element => (
  <LayoutWrapper
    page={page}
    backgroundColor={backgroundColor}
    isHeaderHidden={isHeaderHidden}
    isHeaderTransparent={isHeaderTransparent}
    isFooterHidden={isFooterHidden}
    isFooterTransparent={isFooterTransparent}
  />
);

export default Default;